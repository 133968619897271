import styled, {createGlobalStyle} from "styled-components"; 

/*Layout*/

export const GlobalStyles = createGlobalStyle`
html {
  font-size: 62.5%;
}

body {
  font-family: 'Lato', sans-serif;
  margin: 0 !important;
  padding: 0 !important; 
}

a {
  text-decoration: none;
  list-style-type: none;
  margin:0 0.5rem;
}

h1 {
  font-size: 3rem;
  text-tranform: uppercase;
}

h2 {
  font-size: 2.5rem;
  
}

h3 {
  font-size: 2.2rem;
  
}

h4 {
  font-size: 2.1rem;
}

h5 {
  font-size: 2rem;
}

h6 {
  font-size: 1.9rem;
}

p {
  font-size: 1.8rem;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
`
export const LayoutWrapper = styled.main`
  width: 100%;
  min-height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: whitesmoke; 
  margin: 0 auto;
  padding: 0;
  overflow-x: hidden;
`
/*Card Items*/

export const PortfolioItemsWrapper = styled.ul`
max-width: 120rem;
display:flex;
justify-content: center;
flex-wrap: wrap;
padding: 0;
margin: 0 0 10rem 0;
`
export const EduItemsWrapper = styled.ul`
max-width: 120rem;
display: flex;
justify-content: center;
align-items:center;
flex-wrap: wrap;
padding: 0;
margin: 0 0 10rem 0;

${'' /* @media screen and (max-width: 1400px) {

} */}

`
export const CardItem = styled.li`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: 28rem;
color: white;
box-shadow: grey 0px 0px 0.625rem 0px;
overflow: hidden;
border-radius: 3px;
margin: 1.2rem;
transition: transform 1s;
cursor: pointer;

:hover {
    box-shadow: grey 0.25rem 0.25rem 0.625rem 0.25rem;
}

`
export const EduItem = styled.li`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
min-width: 32rem;
object-fit: cover;
padding: 0; 
box-shadow: grey 0px 0px 0.625rem 0px;
overflow: hidden;
border-radius: 3px;
margin: 1.2rem;
transition: transform 1s;
cursor: pointer;
:hover {
  box-shadow: grey 0.25rem 0.25rem 0.625rem 0.25rem;
  z-index: 100;
  transform: scale(1.2);
}
@media screen and (max-width: 1100px) {
  min-width: 22rem;
}
`
export const CardItemText = styled.div`
position: relative;
width:100%;
display: flex;
flex-direction: column;
align-items: center;
background-color: rgb(16,10,12); 
margin: 0;
padding-left: 1rem;

h3 {
margin-top:1rem;
margin-bottom: 0;
}

p {
text-align: center;
font-size:1.5rem;
}

li {
  font-size: 1.2rem;
}
`
export const HorizontalList = styled.ul`
display: flex;
flex-driection: row;
list-style: none;
justify-content: flex-start;
align-items: center;
margin: 0;
padding: 0;

li {
  margin: 0;
  margin-right: 0.5rem;
}

`
export const CardLinkDiv = styled.div`
display: flex;
align-self: center;
justify-content: space-between;
margin-bottom: 1rem;
`
export const CardLinkButton = styled.a`
padding: .5rem;
color:rgb(65,105,225);
font-weight: bold;
cursor: pointer;
font-size: 1.8rem;
border-radius: 3px;
-webkit-tap-highlight-color: rgba(0,0,0,0);

:visited {
color:rgb(65,105,225);
}

:hover {
  background: rgb(65,105,225);
  color: white;
}
`

/*Image Styles*/

export const Image = {
  display: 'inline-block',
  marginLeft: 'auto',
  marginRight: 'auto',
  backgroundSize: 'cover',
  backgroundBlendMode: 'screen',
  backgroundPosition: 'top',
  width: '100%',
  height: '100%',
}
export const ImageContainer = styled.div`
    object-fit: cover;
    width: 100%;
    height: 100%;
    padding: 0;  
    margin: 0;
`
export const EduImageContainer = styled.div`
    object-fit: cover;
    width: 100%;
    height: 100%;
    padding: 0;
    opacity: 0.9;  
`
