import React from 'react'
import './overlay.css'

/*Component*/

export default function Overlay({children}) {
    return (
        <div className="overlay-wrapper">
            {children}
        </div>
    )
}
