import React from "react";
import styled from "styled-components"; 

/*Component*/

const HamburgerIcon = ({isMenuOpen}) => {

  const Icon = styled.i`
    color: white;

    :active {
      border: none;
    }
    @media screen and (max-width: 768px){
        color: ${isMenuOpen? `white` : `white` };
      }
  `
    return(
        <>
            <Icon className="fa fa-bars fa-3x" aria-hidden="true" style={{'color':'white'}}></Icon>
        </>        
    )
}

  

export default HamburgerIcon;