import React from "react"
import MainMenu from "../MainMenu/mainMenu";
import Footer from "../Footer/footer"; 
import { Helmet } from "react-helmet";
import {GlobalStyles,LayoutWrapper} from "../styles.js";

/*Component*/

const Layout = ({ children }) => (
  <>
    <Helmet>
      <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
      <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css" integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf" crossorigin="anonymous" />
      <title>Net Knight | Web Developer</title>
    </Helmet>
    <GlobalStyles/>
    <MainMenu />
    <LayoutWrapper>
      {children}
    </LayoutWrapper>
    <Footer />
  </>
);


export default Layout;







// updated layout component with favicon using react helmet

// const Layout = ({ children }) => (
//   <>
//     <StaticQuery query={graphql`
//       {
//         allWordpressWpFavicon{
//           edges{
//             node{
//               url{
//                 source_url
//               }
//             }
//           }
//         }
//       }
//     `} render={props => <Helmet><link rel="icon" href={props.allWordpressWpFavicon.edges[0].node.url.source_url} /></Helmet>} />
//     <MainMenu />
//     <PageContent>
//       {children}
//     </PageContent>
//   </>
// );

// export default Layout;