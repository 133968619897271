import React from 'react';
import logo from '../../images/NetKnight.svg'; 
import './logo.css';

/*Component*/

const Logo = () => (
            <div className="logo-container">
                <img className="logo-img" src={logo} alt='Net Knight logo' />
            </div>
);

export default Logo;