import React from 'react';
import {Link} from 'gatsby';
import "./footer.css";
// import Logo from "../Logo/logo"

const Year = new Date();

/*Component*/

const Footer = () => (
        <footer className="footer-wrapper">
          <section className="footer-inner">
            <div className="footer-links">
              <a className="external-link" href="https://github.com/Zionknight-crypto" target="_blank" rel="noreferrer">
                <i className="fab fa-github"></i>
              </a>
              <a className="external-link" href="https://www.linkedin.com/in/david-hauser-05351523/" target="_blank" rel="noreferrer">
                <i className="fab fa-linkedin"></i>
              </a>
            </div>
            <p className="copy-right">© Net Knight {Year.getFullYear()}</p>
            <Link to={'/contact'} >
              <h3 className="copy-right">Contact</h3>
            </Link>
          </section>
        </footer>
    );

export default Footer;
