import React,{useState} from 'react';
import {Link} from 'gatsby';
import Logo from '../Logo/logo';
import HamburgerIcon from '../HamburgerIcon/HamburgerIcon'; 
import Overlay from '../Overlay/Overlay';
import './mainMenu.css';

/*Component*/

const MainMenu = () => {

  let [isMenuOpen, setIsMenuOpen] = useState(false);
    
  const toggle = () => {
    setIsMenuOpen(!isMenuOpen)
  }

return(
      <header>
        <nav className="nav-wrapper">
          <div className="nav-inner">
            <Link to={'/'}>
              <Logo />
            </Link>
            <ul className="nav-list" role="presentation">
              <li className="nav-list__item">
                  <Link to={`/`}>
                    {` Home`}
                  </Link>
              </li>
              <li className="nav-list__item">
                  <Link to={`/portfolio`}>
                    {` Portfolio`}
                  </Link>
              </li>
              <li className="nav-list__item">
                  <Link to={`/education`}>
                    {` Education`}
                  </Link>
              </li>
              <li className="nav-list__item">
                  <Link to={`/contact`}>
                    {` Contact`}
                  </Link>
              </li>
            </ul>
          </div>
            
            {
              !isMenuOpen ? 
              <div className="hamburger-container" onClick={toggle} onKeyDown={toggle} role="button" tabIndex={0}>
              <HamburgerIcon isMenuOpen={isMenuOpen} aria-label="open menu" />
              </div> 
            : 
            <div className="hamburger-container" onClick={toggle} onKeyDown={toggle} role="button" tabIndex={0}>
              <i className="fas fa-times fa-3x" aria-hidden="true" aria-label="close menu" style={{color:'white', paddingRight: '4px',cursor:'pointer'}}></i>
            </div> }
            
              {isMenuOpen &&
            <Overlay>
                <ul className="overlay__nav-list">
                  <li className="nav-list__item-overlay">
                    <Link to={`/`}>
                      {` Home`}
                    </Link>
                  </li>
                  <li className="nav-list__item-overlay">
                    <Link to={`/portfolio`}>
                      {` Portfolio`}
                    </Link>
                  </li>
                  <li className="nav-list__item-overlay">
                    <Link to={`/education`}>
                      {` Education`}
                    </Link>
                  </li>
                  <li className="nav-list__item-overlay">
                    <Link to={`/contact`}>
                      {` Contact`}
                    </Link>
                  </li>
                </ul>
            </Overlay>
            }
        </nav>    
      </header>  
)};

export default MainMenu;



